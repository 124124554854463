export const apiKey = 'ELC418kI1l7LemEPImSbp5tcMndO8E9v1vNcwvLM'
export const stage = 'prod'
export const domain = `https://api.tis.coke.com`
export const employee = `/employees`
export const formula = `/komix-service/formulas`
export const ingredient = `/komix-service/ingredients`
export const picasso = `/komix-service/picasso`
export const outputDocument = `/komix-service/output-documents`
export const region = `/regions`
export const azureClientId = 'c8c890f2-c9ee-450d-8e41-7e2884f06f60'
export const azureTenantId = '548d26ab-8caa-49e1-97c2-a1b1a06cc39c' 
